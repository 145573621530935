<template>
  <v-container v-if="carregou" fluid>
    <div v-if="inscricaoRecurso">
      <v-row justify="center">
        <v-col cols="auto">
          <v-card width="1200">
            <v-card-title class="justify-center titulo">
              {{ processo.nome }}
            </v-card-title>
            <v-divider/>
            <div>
              <v-card-subtitle class="text-center">
                <v-icon color="accent"> mdi-clipboard-edit-outline</v-icon>
                <h2 style="color: #f28c00">Recurso</h2>
              </v-card-subtitle>
              <div class="text-end text-caption mr-9">
                * Campos obrigatórios
              </div>
              <v-card-text>
                <v-card flat>
                  <v-form ref="recursoForm">
                    <v-card-title class="tituloForm">Avaliação da Documentação</v-card-title>
                    <v-card-text class="mx-2">
                      <v-sheet class="pa-2" outlined>
                        <p style="white-space: pre-line" class="text-justify">
                          {{ inscricaoRecurso.avaliacao }}
                        </p>
                      </v-sheet>
                    </v-card-text>
                    <v-card-title v-if="inscricaoRecurso.pediuRecurso" class="tituloForm">Recurso solicitado
                    </v-card-title>
                    <v-card-title v-else class="tituloForm">Recurso
                    </v-card-title>
                    <v-card-text class="mx-2">
                      <div v-if="inscricaoRecurso.pediuRecurso && !isEditandoRecurso">
                        <v-sheet class="pa-2" outlined>
                          <p style="white-space: pre-line" class="text-justify">
                            {{ inscricaoRecurso.justificativaCandidato }}
                          </p>
                        </v-sheet>
                      </div>
                      <div v-else-if="!inscricaoRecurso.pediuRecurso || isEditandoRecurso">
                        <v-textarea :rules="[campoObrigatorioRule, tamanhoMaximoRule(2000)]" :counter="2000" outlined
                                    label="Redija aqui a justificativa do seu recurso *"
                                    v-model="mensagem">
                        </v-textarea>
                      </div>
                    </v-card-text>
                    <v-card-actions v-if="inscricaoRecurso.pediuRecurso" class="justify-center">
                      <v-btn v-if="inscricaoRecurso.pediuRecurso && !isEditandoRecurso"
                             @click="isEditandoRecurso = true"
                             color="primary">
                        Editar Recurso
                      </v-btn>
                      <v-btn v-if="isEditandoRecurso" @click="isEditandoRecurso = false" color="error">
                        Cancelar Edição
                      </v-btn>
                      <v-btn v-if="isEditandoRecurso" @click="salvarRecurso()" color="primary">
                        Salvar Alterações
                      </v-btn>
                    </v-card-actions>
                    <div v-if="processo.recursoPossuiEnvioDocumentos">
                      <v-card-title v-if="inscricaoRecurso.pediuRecurso && !isEditandoDocumentos" class="tituloForm">
                        Documentos Enviados
                      </v-card-title>
                      <EnvioDocumentosRecurso v-model="arquivosEscolhidos"
                                              v-else-if="!inscricaoRecurso.pediuRecurso || isEditandoDocumentos"
                                              :mensagemAviso="inscricaoRecurso.pediuRecurso"/>
                      <v-card-text v-if="inscricaoRecurso.pediuRecurso && !isEditandoDocumentos" class="mx-2">
                        <v-sheet class="pa-2" outlined v-if="inscricaoRecurso.enviouDocumentos">
                          <v-row
                              dense
                              v-for="documento in inscricaoRecurso.documentosEnviados"
                              :key="documento"
                          >
                            <v-col>
                              {{ documento }}
                            </v-col>
                          </v-row>
                        </v-sheet>
                        <v-sheet class="pa-2" outlined v-else>
                          <v-row>
                            <v-col>
                              Nenhum documento enviado
                            </v-col>
                          </v-row>
                        </v-sheet>
                      </v-card-text>
                      <v-card-actions class="justify-center">
                        <v-btn v-if="inscricaoRecurso.pediuRecurso && !isEditandoDocumentos"
                               @click="isEditandoDocumentos = true"
                               color="primary">
                          Alterar Documentos
                        </v-btn>
                        <v-btn v-if="isEditandoDocumentos" @click="isEditandoDocumentos = false" color="error">
                          Cancelar Envio
                        </v-btn>
                        <v-btn v-if="isEditandoDocumentos" @click="toggleShowModalConfirmacao(true)" color="primary">
                          Enviar Documentos
                        </v-btn>
                      </v-card-actions>
                    </div>
                    <v-card-actions v-if="!inscricaoRecurso.pediuRecurso" class="justify-center">
                      <v-btn @click="solicitarRecurso()" color="primary">
                        Solicitar Recurso
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-card-text>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog v-model="modalConfirmacao" width="500">
        <v-card>
          <v-card-title class="primary justify-start white--text">
            Confirmação de Envio de Documento
          </v-card-title>
          <v-card-text
              style="font-size: 15pt;
              text-align: start"
              class="mt-5 font-weight-bold"
          >
            Deseja enviar os documentos que foram carregados? Ao fazer isso os documentos enviados anteriormente,
            no recurso, serão substituídos por estes.

            <v-card-actions class="justify-center mt-1">
              <v-btn @click="toggleShowModalConfirmacao(false)" color="error">
                Cancelar Envio
              </v-btn>
              <v-btn color="primary" @click="salvarDocumentos(); toggleShowModalConfirmacao(false);">
                Confirmar Envio
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <div v-else>
      <v-row
          align="center"
          class="mt-10"
          justify="center"
      >
        <v-alert type="info" prominent colored-border border="left" elevation="1">
          Não há necessidade ou possibilidade de você solicitar recurso neste processo
        </v-alert>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import processoService from "@/services/processo.service";
import inscricaoRecursoService from "@/services/inscricaoRecurso.service";
import EnvioDocumentosRecurso from "@/components/pages/Inscricao/EnvioDocumentosRecurso.vue";
import rules from "@/commons/rules"
import {mapActions} from "vuex";

export default {
  name: "Recurso",

  components: {
    EnvioDocumentosRecurso
  },

  data() {
    return {
      processo: null,
      inscricaoRecurso: null,
      carregou: false,
      arquivosEscolhidos: [],
      isEditandoRecurso: false,
      isEditandoDocumentos: false,
      mensagem: "",
      modalConfirmacao: false
    };
  },

  async created() {
    let loader = this.$loading.show();

    await processoService
        .recuperarProcessoAbertoParaRecurso(this.$route.params.oid)
        .then(response => {
          this.processo = response.data;
        })
        .catch(() => {
          this.$router.push({name: "404"}).catch(() => {
          });
        });

    await this.recuperarInscricaoRecurso();

    this.carregou = true;
    loader.hide();
  },

  methods: {
    ...rules,
    ...mapActions("avisos", ["exibirAviso"]),

    async recuperarInscricaoRecurso() {
      await inscricaoRecursoService
          .recuperarInscricaoRecursoUsuarioLogado(this.processo.oid)
          .then(async response => {
            this.inscricaoRecurso = response.data;
            this.mensagem = this.inscricaoRecurso.justificativaCandidato;
          })
          .catch(() => {
          });
    },

    async solicitarRecurso() {
      if (this.$refs.recursoForm.validate()) {
        let loader = this.$loading.show();

        await inscricaoRecursoService.atualizarInscricaoRecurso(this.mensagem, this.inscricaoRecurso, this.arquivosEscolhidos)
            .then(async () => {

              await this.recuperarInscricaoRecurso();

              this.exibirAviso({
                mensagem: "Recurso solicitado com sucesso!",
                tipo: "success"
              });
            }).catch(() => {
              this.exibirAviso({
                mensagem: "Erro ao solicitar recurso!",
                tipo: "error"
              });
            })
        loader.hide();
      }
    },

    async salvarRecurso() {
      let arquivos = [];

      if (this.$refs.recursoForm.validate()) {
        let loader = this.$loading.show();

        await inscricaoRecursoService.atualizarInscricaoRecurso(this.mensagem, this.inscricaoRecurso, arquivos)
            .then(async () => {

              await this.recuperarInscricaoRecurso();
              this.isEditandoRecurso = false;

              this.exibirAviso({
                mensagem: "Recurso salvo com sucesso!",
                tipo: "success"
              });
            }).catch(() => {
              this.exibirAviso({
                mensagem: "Erro ao salvar recurso!",
                tipo: "error"
              });
            })
        loader.hide();
      }
    },

    async salvarDocumentos() {
      let justificativa = this.inscricaoRecurso.justificativaCandidato;

      if (this.$refs.recursoForm.validate()) {

        let loader = this.$loading.show();

        if (this.arquivosEscolhidos.length === 0) {
          this.exibirAviso({
            mensagem: "Nenehum documento a enviar",
            tipo: "warning"
          });
        } else {
          await inscricaoRecursoService.atualizarInscricaoRecurso(justificativa, this.inscricaoRecurso, this.arquivosEscolhidos)
              .then(async () => {

                await this.recuperarInscricaoRecurso();
                this.isEditandoDocumentos = false;

                this.exibirAviso({
                  mensagem: "Documentos enviados com sucesso!",
                  tipo: "success"
                });
              }).catch(() => {
                this.exibirAviso({
                  mensagem: "Erro ao enviar documentos!",
                  tipo: "error"
                });
              })
        }
        loader.hide();

      }
    },

    toggleShowModalConfirmacao(valor) {
      this.modalConfirmacao = valor
    }

  }
};
</script>
