import Vue from "vue";

export default {
    recuperarInscricaoRecursoUsuarioLogado(processoOid) {
        return Vue.axios.get("inscricaoRecurso", {
            params: {
                processoOid
            }
        });
    },

    atualizarInscricaoRecurso(mensagem, inscricaoRecurso, arquivos) {
        inscricaoRecurso.pediuRecurso = 1;
        inscricaoRecurso.justificativaCandidato = mensagem;

        arquivos.length > 0 ? inscricaoRecurso.enviouDocumentos = 1 : inscricaoRecurso.enviouDocumentos = 0

        let formData = new FormData();

        arquivos.forEach(file => formData.append("files", file));

        formData.append("inscricaoRecurso", 
                        new Blob([JSON.stringify(inscricaoRecurso)], {type: "application/json"}))

        return Vue.axios.put("inscricaoRecurso", formData, {
            headers: {"Content-Type": "multipart/form-data"}

        })
    }
}